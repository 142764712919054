import React from 'react';
import Title from 'components/title';
import styled from 'styled-components';
import theme from 'constants/theme';
//import STRIPE_PUBLISHABLE from 'constants/stripe';

// hardcoded amount (in US cents) to charge users
// you could set this variable dynamically to charge different amounts
const amount = 15000;

export const CardStyle = styled.div`
  padding: 3rem;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  background-color: #ffffff;
  border-radius: 6px;
  margin: 0 0 3rem 0;
  text-align: center;
`;

export const ButtonStyle = styled.button`
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  outline: none;
  padding: 12px 0;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${theme.colors.primary};
  border-radius: 6px;
  letter-spacing: 1.5px;
  cursor: pointer;
  width: 100%;
`;

export const ParagraphStyle = styled.p`
  margin-top: 1rem;
  min-height: 90px;
`;

// Below is where the checkout component is defined.
// It has several functions, and some default state variables.
const CheckoutCms = class extends React.Component {
  state = {
    disabled: false,
    buttonText: 'CMS Subscription',
    paymentMessage: '',
  };

  resetButton() {
    this.setState({ disabled: false, buttonText: 'CMS Subscription' });
  }

  componentDidMount() {
    this.stripeHandler = window.StripeCheckout.configure({
      key: `${process.env.STRIPE_PUBLIC_KEY}`,
      closed: () => {
        this.resetButton();
      },
    });
  }

  openStripeCheckout(event) {
    event.preventDefault();
    this.setState({ disabled: true, buttonText: 'Waiting...' });
    this.stripeHandler.open({
      name: 'CMS Subscription',
      amount: amount,
      description:
        'Our CMS plan is for database driven websies that require contained environments',
      zipCode: 'true',
      billingAddress: 'true',
      allowRememberMe: 'false',
      image: `${process.env.WEBSITE_STRIPE_LOGO}`,
      token: token => {
        fetch(`${process.env.FUNCTION_API}` + 'subscription/cms', {
          method: 'POST',
          body: JSON.stringify({
            token,
            amount,
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        })
          .then(res => {
            this.resetButton();
            this.setState({ paymentMessage: 'Payment Successful!' });
            return res.json();
          })
          .catch(() => {
            this.setState({ paymentMessage: 'Payment Failed' });
          });
      },
    });
  }

  render() {
    return (
      <CardStyle>
        <Title tag="h2">CMS Hosting</Title>
        <ParagraphStyle>
          Our CMS plan is for database driven websies that require contained
          environments.
        </ParagraphStyle>
        {this.state.paymentMessage}
        <ButtonStyle
          onClick={event => this.openStripeCheckout(event)}
          disabled={this.state.disabled}
        >
          {this.state.buttonText}
        </ButtonStyle>
      </CardStyle>
    );
  }
};

export default CheckoutCms;
